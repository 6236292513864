export const mapStyle  = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [
            {
                "hue": "#ff0000"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#0088ff"
            }
        ]
    }
];

export function infoWindowContent(place) {
    let placeName = place.name.substring(0,11);

    let content = document.createElement("div");
    let div = document.createElement("div");
    content.appendChild(div);

    let h2 = document.createElement('h2');
        h2.classList.add("info-window__caption")
        h2.innerText = placeName;
    div.appendChild(h2);

    let h3 = document.createElement('h3');
        h3.classList.add("info-window__label")
        h3.innerText = place.formatted_address;
    div.appendChild(h3);

    let ratingDiv = document.createElement("div");
        ratingDiv.classList.add("rating-container");

    let h3_1 = document.createElement('h3');
        h3_1.classList.add("info-window__label-rating")
        h3_1.innerText = "Rating: " + place.rating;
    ratingDiv.appendChild(h3_1);

    let stars = document.createElement("div");
    stars.classList.add("stars");

    for (let index = 0; index < 5; index++) {
        let star = document.createElement("div");
        star.classList.add("fa");
        star.classList.add("fa-star");
        star.classList.add("checked");

        stars.appendChild(star);
    }

    ratingDiv.appendChild(stars);
    div.appendChild(ratingDiv);

    let a_ = document.createElement('a');
    a_.classList.add("info-window__link");
        a_.innerText = "Show map";
        a_.href = "https://www.google.com/search?q=g-group%20pozna%C5%84&ei=Wg9XYrP2Lqv2qwH1yopY&ved=2ahUKEwjynrnyzpH3AhVomYsKHaxPCUgQvS56BAgVEAE&uact=5&oq=g-group+pozna%C5%84&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6BggAEAcQHjoICAAQCBAHEB5KBAhBGABKBAhGGABQiwlYmxJgyhNoAXABeACAAeYBiAGBBJIBBTEuMC4ymAEAoAEByAEIwAEB&sclient=gws-wiz&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=6474986359291598327&lqi=Cg9nLWdyb3VwIHBvem5hxYRaESIPZyBncm91cCBwb3puYcWEkgEQc29mdHdhcmVfY29tcGFueaoBDxABKgsiB2cgZ3JvdXAoCw&rlst=f#rlfi=hd:;si:6474986359291598327,l,Cg9nLWdyb3VwIHBvem5hxYRaESIPZyBncm91cCBwb3puYcWEkgEQc29mdHdhcmVfY29tcGFueaoBDxABKgsiB2cgZ3JvdXAoCw;mv:[[52.4116824,16.941932899999998],[52.3994602,16.9196469]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2"
    div.appendChild(a_);

    return content;
}
