import markerIcon from "../img/g-group-icon-marker.webp";
import Swiper, {Autoplay, Mousewheel, Navigation, Pagination} from 'swiper';
import {infoWindowContent, mapStyle} from './mapStyle';

// Avoid `console` errors in browsers that lack a console.
(function () {
  var method;
  var noop = function () {
  };
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.

Swiper.use([Autoplay, Navigation, Pagination, Mousewheel]);

const howCanWeHelpSwiper = new Swiper('#how-can-we-help-swiper', {
  direction: 'horizontal',
  loop: false,
  slidesPerView: 2.5,
  enabled: true,
  breakpoints: {
    425: {
      slidesPerView: 3.5,
      enabled: true,
    },
    768: {
      slidesPerView: 5,
      enabled: false,
    }
  }
});

const caseStudiesSwiper = new Swiper('#case-studies-swiper', {
  direction: 'horizontal',
  speed: 400,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  loop: true,
  slidesPerView: 1.25,
  enabled: true,
  pagination: {
    el: "#case-studies-swiper-pagination",
  },
  navigation: {
    nextEl: "#case-studies-swiper-button-next",
    prevEl: "#case-studies-swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 2,
      direction: 'vertical',
    },
    768: {
      slidesPerView: 1.55,
      direction: 'horizontal'
    },
    960: {
      slidesPerView: 1.75,
      direction: 'horizontal'
    },
    1252: {
      slidesPerView: 2,
      direction: 'horizontal'
    }
  }
});

const caseStudiesSwiperForAbroad = new Swiper('#case-studies-swiper-for-abroad', {
  direction: 'horizontal',
  speed: 400,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  loop: true,
  slidesPerView: 1.25,
  enabled: true,
  pagination: {
    el: "#case-studies-for-abroad-swiper-pagination",
  },
  navigation: {
    nextEl: "#case-studies-for-abroad-swiper-button-next",
    prevEl: "#case-studies-for-abroad-swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 2,
      direction: 'vertical',
    },
    768: {
      slidesPerView: 1.55,
      direction: 'horizontal'
    },
    960: {
      slidesPerView: 1.75,
      direction: 'horizontal'
    },
    1252: {
      slidesPerView: 2,
      direction: 'horizontal'
    }
  }
});

const reviewsSwiper = new Swiper('#reviews-swiper', {
  slidesPerView: 2,
  loop: true,
  mousewheel: {
    forceToAxis: true,
    thresholdTime: 500,
  },
  pagination: {
    el: "#reviews-swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: "#reviews-swiper-button-next",
    prevEl: "#reviews-swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      direction: 'vertical',
      spaceBetween: 100,
      allowTouchMove: false,
    },
    768: {
      slidesPerView: 2,
      allowTouchMove: true,
      direction: 'horizontal'
    },
  }
});

const techSolutionsSwiper = new Swiper('#tech-solutions-swiper', {
  direction: 'horizontal',
  loop: false,
  slidesPerView: 2,
  enabled: true,
  breakpoints: {
    425: {
      slidesPerView: 2,
      enabled: true,
    },
    768: {
      slidesPerView: 2,
      enabled: false,
    }
  }
});

const teamSwiper = new Swiper('#team-swiper', {
  slidesPerView: 3.7,
  spaceBetween: 10,
  pagination: {
    el: "#team-swiper-pagination",
    type: "progressbar",
  },
  navigation: {
    nextEl: "#team-swiper-button-next",
    prevEl: "#team-swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1.5,
    },
    400: {
      slidesPerView: 1.9,
    },
    550: {
      slidesPerView: 2.7,
    },
    850: {
      slidesPerView: 3.7,
    }
  }
});


const staffFeedback = new Swiper('#staff-feedback-swiper', {
  slidesPerView: 2,
  loop: true,
  mousewheel: {
    forceToAxis: true,
    thresholdTime: 500,
  },
  navigation: {
    nextEl: "#staff-feedback-swiper-button-next",
    prevEl: "#staff-feedback-swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      direction: 'vertical',
      spaceBetween: 100,
      allowTouchMove: false,
    },
    768: {
      slidesPerView: 2,
      allowTouchMove: true,
      direction: 'horizontal'
    },
  }
});


window.initMap = function () {
  let service;
  const ourLocation = { lat: 52.40688959497816, lng: 16.920867411062346 };
  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: 16,
    center: ourLocation,
    styles: mapStyle,
  }
);

const marker = new google.maps.Marker({
  position: ourLocation,
  map: map,
  icon: markerIcon
});

var request = {
  placeId: 'ChIJZzBVWdlEBEcR91UTr8nF21k',
  fields: ['name', 'rating','formatted_address']
};

service = new google.maps.places.PlacesService(map);
service.getDetails(request, function(place,status){
  if (status == google.maps.places.PlacesServiceStatus.OK) {
    console.log(place);

    const infowindow = new google.maps.InfoWindow({
      content: infoWindowContent(place)
    });

    infowindow.open(map,marker);
  }
});
}
